import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
import { ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client';
import App from './App';
//import './utils/checkTheme';
import './utils/setVh';
import './index.css';

const TRACKING_ID = "G-W5RLPQWYLQ";
ReactGA.initialize(TRACKING_ID);

const environment = 'master';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: 'https://graphql.contentful.com/content/v1/spaces/k1jri2uqwkbn/environments/' + environment,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer bWgFlfrSJjk0zoJtuLTt-RM_eRjsYfZcZxr3jY6geUI'
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <ApolloProvider client={client}>
        <App/>
    </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
