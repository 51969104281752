import Leaderboard from '../components/leaderboard/Leaderboard'
//import QuarterKahoots from '../components/quarter-kahoots/QuarterKahoots'
import { currentQuarter } from '../utils/quarter'
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as QuarterBanner } from '../images/quarter-banner.svg';

const quarter = new Date().getFullYear() + ' Q' + currentQuarter;
const Start = () => (
  <div className='start w-full flex flex-col'>
    <div className='flex flex-col items-center mb-12 lg:mb-24'>
      <Logo />
      <div className='-mt-5 flex items-center justify-center relative'>
        <QuarterBanner/>
        <span className='absolute text-beige text-lg font-bold'>{quarter}</span>
      </div>
    </div>
    <div className='w-full flex flex-col items-center lg:justify-center mb-10'>
      <Leaderboard/>
      {/*<QuarterKahoots className="mt-6 mx-auto w-full max-w-lg z-20"/> */}
    </div>
  </div>
)

export default Start