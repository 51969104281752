import {gql} from '@apollo/client'

const GET_PAGES = gql`
  query getPages {
    pageCollection {
      items {
        title,
        slug,
        icon {
          icon
        }
        content {
          json
        }
        sys {
          id
        }
      }
    }
  }
`;

const GET_DATA = gql`
  query getData {
    participantCollection {
      items {
        username
      }
    },
    kahootCollection {
      items {
        title,
        date,
        link,
        firstPlace {
          username
        }
        secondPlace {
          username
        }
        thirdPlace {
          username
        }
        fourthPlace {
          username
        }
        fifthPlace {
          username
        }
        creator {
          username
        }
      }
    }
  }
`;

const GET_KAHOOTS = gql`
  query Kahoots {
    kahootCollection {
      items {
        title
        link
        date
        firstPlace {
          username
        }
        secondPlace {
          username
        }
        thirdPlace {
          username
        }
        fourthPlace {
          username
        }
        fifthPlace {
          username
        }
        creator {
          username
        }
      }
    }
  }
`;

export {GET_PAGES, GET_DATA, GET_KAHOOTS}
