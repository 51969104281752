import '../../styles/scss/Leaderboard.scss'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import { GET_DATA } from '../../utils/data'
import { currentQuarter, getQuarter, currentYear, getYear } from '../../utils/quarter'
import Participant from './Participant'
import { points } from '../../utils/points'

function Leaderboard(props) {
	const { data, error, loading } = useQuery(GET_DATA)

	if (loading) return <span>Loading...</span>
	if (error) return `Error! ${error.message}`

	let participants = [...data.participantCollection.items],
		kahootsThisYear = [],
		kahoots = [],
		podium = []

	data.kahootCollection.items.map((kahoot, i) => (getYear(new Date(kahoot.date)) === currentYear ? kahootsThisYear.push(kahoot) : null))
	kahootsThisYear.map((kahoot, i) => (getQuarter(new Date(kahoot.date)) === currentQuarter ? kahoots.push(kahoot) : null))

	let leaderboard = createLeaderboard(kahoots, participants, points)
	console.log(kahoots)

	leaderboard.map((participant, i) => (i < 3 ? podium.push(participant) : null))

	return (
		<>
			{leaderboard.length > 0 ? (
				<div className={`leaderboard`}>
					{loading ? (
						<span>Loading...</span>
					) : leaderboard ? (
						<>
							<div className="podium">
								{podium.map((participant, i) => (
									<Participant data={participant} place={i} key={i} />
								))}
							</div>
							<div className="losers">{leaderboard.map((participant, i) => (i > 2 ? <Participant data={participant} place={i} key={i} /> : null))}</div>
						</>
					) : null}
				</div>
			) : (
				<div className="no-played">
					<h1>Aj aj aj, här har det inte spelats några kahoots</h1>
				</div>
			)}
		</>
	)
}

function createLeaderboard(kahoots, participants, points) {
	let score = []

	participants.map((participant, i) =>
		kahoots.map((kahoot, i) =>
			kahoot.firstPlace.username === participant.username
				? score.push({
						username: participant.username,
						score: points['firstPlace'],
						created: 0,
				  })
				: kahoot.secondPlace.username === participant.username
				? score.push({
						username: participant.username,
						score: points['secondPlace'],
						created: 0,
				  })
				: kahoot.thirdPlace.username === participant.username
				? score.push({
						username: participant.username,
						score: points['thirdPlace'],
						created: 0,
				  })
				: kahoot.fourthPlace.username === participant.username
				? score.push({
						username: participant.username,
						score: points['fourthPlace'],
						created: 0,
				  })
				: kahoot.fifthPlace.username === participant.username
				? score.push({
						username: participant.username,
						score: points['fifthPlace'],
						created: 0,
				  })
				: kahoot.creator.username === participant.username
				? score.push({
						username: participant.username,
						score: points['creator'],
						created: 1,
				  })
				: score.push({
						username: participant.username,
						score: 0,
						created: 0,
				  })
		)
	)

	let leaderboard = _(score)
		.groupBy('username')
		.map((array) => ({ ...array[0], score: _.sum(_.map(array, 'score')), created: _.sum(_.map(array, 'created')) }))
		.value()
		.sort((a, b) => (a.score > b.score ? -1 : 1))

	// Don't include participants with no points
	leaderboard = leaderboard.filter((obj) => obj.score !== 0)

	return leaderboard
}

export default Leaderboard
