//Covert object of objects to array of objects and 
//remove all undefined and null objects
function compactObject(data) {
  if (typeof data !== 'object') {
    return data;
  }

  return Object.keys(data).reduce(function(accumulator, key) {
    const isObject = typeof data[key] === 'object';
    const value = isObject ? compactObject(data[key]) : data[key];
    const isEmptyObject = isObject && !Object.keys(value).length;
    if (value === undefined || isEmptyObject) {
      return accumulator;
    }

    return Object.assign(accumulator, {[key]: value});
  }, {});
}

export {compactObject}