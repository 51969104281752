import { NavLink } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { Star, BarChart2, Clipboard, Award } from 'react-feather';
import { MenuButton, MenuIcon, X } from '../icons/icons'
//import Toggle from './Toggle'

function Nav(props) {
  const [isActive, setActive] = useState(false),
        toggleMenu = () => {
          setActive(!isActive)
        },
        getAllWithClass = (className) => document.getElementsByClassName(className),
        navLinks = Array.from(getAllWithClass('menu-item')),
        ref = useRef(null);

  navLinks.forEach(link => {
    link.addEventListener('click', toggleMenu)
  })

  useEffect((isActive) => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(isActive);
      }
    }
    document.addEventListener('click', handleClickOutside, true);
    return () => {
        document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <>
    <div className="flex flex-col lg:items-start lg:fixed z-50 lg:h-screen p-7 lg:p-12">
    <button className='toggleNav flex items-center justify-center relative z-50' onClick={(toggleMenu)} aria-label='Open navigation'>
      <MenuButton className='w-14 h-16 lg:w-28 lg:h-32' />
      <span className='icon absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 w-3.5 h-3 lg:w-8 lg:h-6'>{isActive ? <X /> : <MenuIcon />}</span>
    </button>
    <nav ref={ref} className={`fixed z-30 left-0 top-0 transition-transform w-full lg:w-80 3xl:w-96 h-auto lg:h-full lg:pl-10 lg:pb-10 lg:pt-56 flex flex-col items-center justify-center 
      ${isActive ? 'translate-y-28 lg:translate-y-0 lg:translate-x-0': '-translate-y-full lg:translate-y-0 lg:-translate-x-full'}`}>
      <div className='menu flex flex-col flex-grow p-8 pt-12'>
        <NavLink to='/' className="menu-item"><BarChart2 size={20} />Leaderboard</NavLink>
        <NavLink to='/hall-of-fame' className="menu-item"><Award size={20} />Hall of Fame</NavLink>
        {props.pages ?
          props.pages.map((page, i) => (
            <NavLink key={i} to={'/' + page.slug} className="menu-item">
              <Clipboard size={20} />
              {page.title}
            </NavLink>
          ))
        : null }
        <NavLink to='/poang' className="menu-item"><Star size={20}/>Poäng</NavLink>
        {/* <NavLink to='/spelade-kahoots' className="menu-item"><Archive size={20} />Spelade Kahoots</NavLink>
        <NavLink to='/deltagare' className="menu-item"><Users size={20} />Deltagare</NavLink> */}
      </div>
      {/* <div className='settings p-3 py-6 lg:px-6 flex items-center justify-between bg-slate-200 dark:bg-slate-900/50'>
        <label className='flex items-center space-x-4'><Moon size={20} /><span>Mörkt läge</span></label>
        <Toggle dest='html' toggleClass='dark' store='theme'/>
      </div> */}
    </nav>
    </div>
    </>
  );
};


// function getIcon(icon) {
//   const Icon = lazy(() => import(`../../../node_modules/react-feather/${icon}`));

//   return(
//     <Suspense fallback={<div>Loading...</div>}>
//       <Icon size={20}/>
//     </Suspense>
//   )
// }

export default Nav