import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {useQuery} from '@apollo/client'
import {GET_PAGES} from './utils/data'
//import RouteChangeTracker from './utils/ga-router'
import Nav from './components/Nav'
import Start from './pages/start'
import HallOfFame from './pages/halloffame'
import Points from './pages/points'
import Page from './pages/page'
//import Participants from './pages/participants'
//import Kahoots from './pages/played-kahoots';
import './styles/scss/App.scss'


function App () {
  const {data, error, loading} = useQuery(GET_PAGES)

  if (loading) return <span>Loading pages</span>;
  if (error) return `Error! ${error.message}`;

  var pages = [...data.pageCollection.items];

  return(
    <>
      {/* <RouteChangeTracker/> */}
      <Nav pages={pages}/>
      <main className='wrapper'>
        <Routes>
          <Route path='/' element={<Start/>} />
          <Route path='/hall-of-fame' element={<HallOfFame/>} />
          {pages ? 
            pages.map((page, i) => (
              <Route key={i} path={'/' + page.slug} element={<Page data={page} />} />
            ))
          : null }
          <Route path='/poang' element={<Points />} />
          {/* <Route path='/deltagare' element={<Participants/>} />
          <Route path='/spelade-kahoots' element={<Kahoots/>} /> */}
          <Route element={Error} />
        </Routes>
      </main>
    </>
  )
}

export default App;
