//import {GET_DATA} from './data'
//import {useQuery} from '@apollo/client'
const currentQuarter = getQuarter(new Date());
const currentYear = getYear(new Date());

// function GetQuarterKahoots(data) {
//   let kahoots = []
//   data.kahootCollection.items.map((kahoot, i) => (
//     getQuarter(new Date(kahoot.date)) === currentQuarter
//     ? kahoots.push(kahoot) 
//     : null
//   ))
//   return(kahoots)
// }

function getQuarter(date) {
  return Math.floor(date.getMonth() / 3 + 1)
}

function getYear(date) {
  return date.getYear()
}



export { getQuarter, currentQuarter, currentYear, getYear }