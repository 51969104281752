import { points } from '../utils/points';

function Points() {
	return (
		<div className="points px-2 pb-10 lg:-mt-8 max-w-2xl mx-auto">
			<div>
				<h1 className="mb-6">Poängsystem</h1>
				<div className="flex flex-col w-full bg-beige rounded-lg border-4 border-brown">
					<div className="flex items-center justify-between py-2 px-3 text-white font-bold bg-brown rounded-t">
						<p>Placering</p>
						<p>Poäng</p>
					</div>
					<div className="flex items-center justify-between py-2 px-3 bg-transparent">
						<p>Första plats</p>
						<p>{points['firstPlace']}</p>
					</div>
					<div className="flex items-center justify-between p-3 bg-beige-dark">
						<p>Andra plats</p>
						<p>{points['secondPlace']}</p>
					</div>
					<div className="flex items-center justify-between p-3 bg-transparent">
						<p>Tredje plats</p>
						<p>{points['thirdPlace']}</p>
					</div>
					<div className="flex items-center justify-between p-3 bg-beige-dark">
						<p>Fjärde plats</p>
						<p>{points['fourthPlace']}</p>
					</div>
					<div className="flex items-center justify-between p-3 bg-transparent">
						<p>Femte plats</p>
						<p>{points['fifthPlace']}</p>
					</div>
					<div className="flex items-center justify-between p-3 bg-beige-dark">
						<p>Skapa ett quiz</p>
						<p>{points['creator']}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Points;
