import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_DATA } from '../utils/data';
import { points } from '../utils/points';
import { compactObject } from '../utils/compactObject';
import { getQuarter } from '../utils/quarter';
import { ReactComponent as Logo } from '../images/logo.svg';
import Winner from '../components/hall-of-fame/Winner';
import '../styles/scss/Hall-of-Fame.scss';

function HallOfFame() {
	const { data, loading, error } = useQuery(GET_DATA);

	if (loading) return <span>Loading...</span>;
	if (error) return `Error! ${error.message}`;

	let kahoots = [...data.kahootCollection.items],
		participants = [...data.participantCollection.items];

	let quarters = createQuarters(kahoots, participants, points);

	/*console.log('quarters:', quarters);*/

	quarters.slice(0, 0);

	return (
		<div className="hall-of-fame w-full h-full flex flex-col items-center flex-grow">
			<div className="mb-14 lg:mb-24 flex flex-col items-center">
				<Logo />
				<h1 className="mt-4">Hall of Fame</h1>
			</div>
			<div className="winners w-full h-full flex flex-col items-center lg:items-stretch lg:flex-row lg:flex-wrap lg:justify-center max-w-6xl mt-40">
				{quarters.length !== 0 ? quarters.map((quarter, i) => <Winner key={i} data={quarter} />) : <p className="text-center">Inget kvartal spelat</p>}
			</div>
		</div>
	);
}

function createQuarters(kahoots, participants, points) {
	let quarters = [];
	let currentYQ = new Date().getFullYear() + '-Q' + getQuarter(new Date());

	kahoots.map((kahoot, i, kahoots) =>
	(new Date(kahoot.date).getFullYear() + '-Q' + getQuarter(new Date(kahoot.date))) !== currentYQ
			? quarters.push({
					yQ: new Date(kahoot.date).getFullYear() + '-Q' + getQuarter(new Date(kahoot.date)),
					kahoots: {
						title: kahoot.title,
						date: new Date(kahoot.date),
						firstPlace: kahoot.firstPlace.username,
						secondPlace: kahoot.secondPlace.username,
						thirdPlace: kahoot.thirdPlace.username,
						fourthPlace: kahoot.fourthPlace ? kahoot.fourthPlace.username : '',
						fifthPlace: kahoot.fifthPlace ? kahoot.fifthPlace.username : '',
						creator: kahoot.creator.username,
					},
			  })
			: null
	);

	quarters = _(quarters)
		.groupBy('yQ')
		.map((array) => ({ ...array[0], kahoots: _.merge(_.map(array, 'kahoots')) }))
		.value()
		.sort((a, b) => (a.yQ > b.yQ ? -1 : 1));

	function createResults(quarter) {
		var results = [],
			scores = [];
		quarter.kahoots.forEach((kahoot) => {
			scores.push(
				{
					username: kahoot.firstPlace,
					points: points['firstPlace'],
				},
				{
					username: kahoot.secondPlace,
					points: points['secondPlace'],
				},
				{
					username: kahoot.thirdPlace,
					points: points['thirdPlace'],
				},
				kahoot.fourthPlace !== ''
					? {
							username: kahoot.fourthPlace,
							points: points['fourthPlace'],
					  }
					: null,
				kahoot.fifthPlace !== ''
					? {
							username: kahoot.fifthPlace,
							points: points['fifthPlace'],
					  }
					: null,
				{
					username: kahoot.creator,
					points: points['creator'],
				}
			);
		});

		scores = _(scores)
			.groupBy('username')
			.map((array) => ({ ...array[0], points: _.sum(_.map(array, 'points')) }))
			.value();

		results = Object.values(compactObject(scores)).sort((a, b) => (a.points > b.points ? -1 : 1));

		return results;
	}
	quarters.forEach((quarter) => {
		quarter.results = createResults(quarter);
	});

	return quarters;
}

export default HallOfFame;
