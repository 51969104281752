import { ReactComponent as Pedestal } from '../../images/pedestal.svg';

function Winner(props) {
	let winner = props.data,
		yQ = winner.yQ.split('-');
	return (
		<div className="winner" year={yQ[0]} quarter={yQ[1]}>
			<div className="pedestal">
				<Pedestal />
			</div>
			<div className="plaque">
				<h2 className="text-2xl font-bold">{winner.results[0].username}</h2>
				<p className="text-lg font-bold">
					<span>{yQ[0]}</span> <span>{yQ[1]}</span>
				</p>
			</div>
		</div>
	);
}

export default Winner;
