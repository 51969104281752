import { FirstPlace, SecondPlace, ThirdPlace, FourthPlace, FifthPlace, LastPlaceCreator } from '../../icons/icons'

function Participant(props) {
  const data = props.data,
        place = props.place + 1;
  return (
    <>
    <div className={`participant place-${place}`}>
      <div className="data">
        <span className='place'>{
          place === 1 ? <FirstPlace /> :
          place === 2 ? <SecondPlace /> :
          place === 3 ? <ThirdPlace /> :
          place === 4 ? <FourthPlace /> :
          place === 5 ? <FifthPlace /> : 
          place + '.'}
        </span>
        <div className="user">{data.username}</div>
        {data.created > 0 ? (
          <div className="creator" title="Creator">
            {Array(data.created).fill(0).map((star, i) => (
              <span key={i}><LastPlaceCreator/></span>
            ))}
          </div>
        ) : null}
        <div className="score">{data.score}</div>
      </div>
    </div>
    </>
  )
}

export default Participant;