import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import '../styles/scss/Page.scss'

function Page(props) {
  var content = props.data.content.json
  return (
    <div className='page'>
      <div>
        {documentToReactComponents(content)}
      </div>
    </div>
  )
}


export default Page